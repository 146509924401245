import {useState, useEffect, useCallback, useRef} from 'react';
import {useMutation} from '@tanstack/react-query';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import dayjs from 'dayjs';
import Account from './Account'
import Zang from './parts/Zang'
import Ordinary from './parts/Ordinary'
import Creed from './parts/Creed'
import Confiteor from './parts/Confiteor'
import Voorbedelied from './parts/Voorbedelied'
import Proper from './parts/Proper'
import Voorbede from './parts/Voorbede'
import {useParams, useNavigate } from "react-router-dom";
import {api_proxy, ws_proxy, react_proxy} from "./proxy";
import {FormattedMessage, useIntl} from 'react-intl'
import {SupportedLanguagesFacebookURLs} from './LocaleContext'
import Part from './Part'
//import ReactDOMServer from 'react-dom/server';


function Editor() {
    console.log("Editor rendering")
    const intl = useIntl()
    const initialRender = useRef(true)
    const [date, setDate] = useState(formatDate(nextDate(0)))  // next Sunday
    const [patch, setPatch] = useState({});
    const [subscription, setSubscription] = useState(null)
    const [amount, setAmount] = useState(null)
    const [currency, setCurrency] = useState(null)
    const [gospel, setGospel] = useState("");

    const parish_name_title = useRef("")

    // read parish id and (optionally) parish name from URL
    const { lang, parish_id, parish_name, email } = useParams();
    const [sentEmail, setSentEmail] = useState('')
    const navigate = useNavigate();

    // Function to update the gospel
    const updateGospel = (text) => {
        console.log(`Gospel: ${text.slice(0,100)}...`)
        setGospel(text);
    };

    // check if a payment was just made
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // initializing websocket for receiving updates from server
    // and sending updates to server. Sending updates is done by children
    // this component only sends the date it's editing
    const socketUrl = `wss://${window.location.hostname}${ws_proxy}`
    const [socket, setSocket] = useState({socketUrl});
    const { sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(
        socket.socketUrl, {
            retryOnError: true,
            shouldReconnect: (e) => true,
            onError: (e) => console.error("Error in websocket", e),
            onClose: (e) => {setSocket({...socket})
        }
    });
    const connectionStatus = {
        [ReadyState.CONNECTING]: intl.formatMessage({id:'editor.readystate.connecting', defaultMessage:'Connecting...'}),
        [ReadyState.OPEN]: intl.formatMessage({id:'editor.readystate.connected', defaultMessage:'Connected'}),
        [ReadyState.CLOSING]: intl.formatMessage({id:'editor.readystate.not_connected', defaultMessage:'⚠️ Not connected'}),
        [ReadyState.CLOSED]: intl.formatMessage({id:'editor.readystate.not_connected', defaultMessage:'⚠️ Not connected'}),
        [ReadyState.UNINSTANTIATED]: intl.formatMessage({id:'editor.readystate.not_connected', defaultMessage:'⚠️ Not connected'}),
    }[readyState];

    // set mutation for sending mails
    const sendEmail = useMutation({
        mutationFn: ({email, model, parameters}) => {
            setSentEmail(email)
            fetch(`https://${window.location.hostname}${api_proxy}/send-email`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    model: model,
                    lang: lang,
                    parameters: {
                        ...parameters,
                        parish_name: parish_name || parish_name_title.current,
                        secret_url: window.location.protocol + '//' + window.location.host + window.location.pathname
                    }        
                })
            })
        }
    })

    // user selects date
    useEffect(() => {
        console.log(`Editor effect depending on [date] = [${date}]`)
        if (initialRender.current) {
            // don't run this hook when Editor is being mounted, the socket dependency hook will take care
            initialRender.current = false
            return
        }
        if (parish_id) {
            console.log("Editor set state to new date and empty data")
            setPatch({})
            let message = { parish_id, date }
            sendJsonMessage(message)
            console.log(`Editor sending websocket message ${JSON.stringify(message)}`)
        }
        else {
            console.log(`ERROR no parish`)
        }
    }, [date, sendJsonMessage]);

    // let server know what date we're on
    useEffect(() => {
        console.log(`Editor effect depending on [socket]`)
        if (parish_id) {
            let message = {}
            if (parish_name) {
                // this is right after clicking subscribe on the Home page
                // to register the subscription, a message is sent to the server
                // containing the parish name and the email address
                // and also the subject and body text for the email, because
                // the server has no i18n provisions
                let parish_name_decoded = decodeURIComponent(parish_name)
                let email_decoded = decodeURIComponent(email)
                // remember the parish name in this session
                parish_name_title.current = parish_name_decoded
                // send a subscription email
                sendEmail.mutate({email: email_decoded, model:"subscription"})
                // remove the parish_name and email from the URL
                console.log(`Going to remove parish name from URL`)
                navigate(`${react_proxy}/${lang}/edit/${parish_id}/`, {replace: true})
                // send the message to the server containing all info
                message = { 
                    parish_id, 
                    date, 
                    parish_name: parish_name_decoded 
                }
            }
            else {
                message = { 
                    parish_id, 
                    date 
                }
            }
            sendJsonMessage(message)
            console.log(`Editor sending websocket message ${JSON.stringify(message)}`)
        }
        else {
            console.log(`ERROR no parish`)
        }
    }, [socket, sendJsonMessage])

    // message from server, containing update of json data
    useEffect(() => {
        console.log(`Editor effect depending on [lastJsonMessage] = [${lastMessage?.data}]`)
        if (lastJsonMessage) {
            if (lastJsonMessage?.date === date
                && lastJsonMessage?.parish_id === parish_id
                && lastJsonMessage?.patch) {
                setPatch((patch) => {
                    for (let id in lastJsonMessage.patch) {
                        patch[id] = lastJsonMessage.patch[id]
                    }
                    console.log(`Editor set state based on data from websocket: ${JSON.stringify(patch)}`)
                    return {...patch}  // crucial: the returned patch object is DIFFERENT, but of its
                                          // properties, ALL apart from the modified object are reference IDENTICAL
                }) 
            }
            else {
                console.log(`Editor ERROR wrong active date or parish`)
            }
            if (lastJsonMessage?.subscription) {
                setSubscription(lastJsonMessage.subscription)
            }
            if (lastJsonMessage?.amount) {
                setAmount(lastJsonMessage.amount)
            }
            if (lastJsonMessage?.currency) {
                setCurrency(lastJsonMessage.currency)
            }
            if (lastJsonMessage?.parish_name) {
                parish_name_title.current = lastJsonMessage.parish_name
            }
        }
    }, [lastJsonMessage, date])

    function one_week_after(date) {
        let newDate = new Date(date)
        newDate.setDate(newDate.getDate() + 7)
        return newDate
    }
    
    // child sending patch to the server
    const applyPatch = useCallback((id, patch) => {
        console.log(`Editor ${id} syncing to server`)
        let message = {parish_id, date, patch: {}}
        message.patch[id] = patch 
        sendJsonMessage(message)
        console.log(`Ordinary ${id} sending websocket message ${JSON.stringify(message)}`)
    }, [parish_id, date, sendJsonMessage])

    const helperTexts = {
        title: intl.formatMessage({id:'editor.helperText.title',defaultMessage:'displayed as title'}),
        custom_slide_break: intl.formatMessage({id:'editor.helperText.custom_slide_break',defaultMessage:'leave two lines empty where a new slide should begin'}),
        automatic_slide_break: intl.formatMessage({id:'editor.helperText.automatic_slide_break',defaultMessage:'long text is split over multiple slides automatically'})
    }

    return (
        <Container maxWidth="md">
            <Stack spacing={4}>
                <Typography variant="h1" gutterBottom>{parish_name || parish_name_title.current}</Typography>
                {parish_name_title.current &&
                    <Account subscription={subscription} amount={amount} currency={currency} sendEmail={sendEmail.mutate} expand={!!clientSecret}/>
                }
                <Snackbar open={sendEmail.isSuccess}>
                    <Alert severity="success">
                        <FormattedMessage id="editor.email_sent" values={{email: sentEmail}} defaultMessage="An email has been sent to {email}"/>
                    </Alert>
                </Snackbar>
                <Snackbar open={sendEmail.isError}>
                    <Alert severity="error">
                        <FormattedMessage id="editor.email_error" values={{email: sentEmail}} defaultMessage="An error occurred when sending email to {email}"/>
                    </Alert>
                </Snackbar>
                <Button target="_blank" href={`https://${window.location.hostname}${api_proxy}/slides?parish=${parish_id}&date=${date}`} variant="contained">
                    <FormattedMessage id="editor.button.open-presentation" defaultMessage="Start presenting"/>
                </Button>
                {subscription && 
                    <Button target="_blank" href={`https://${window.location.hostname}${api_proxy}/voorbeden?parish=${parish_id}&date=${date}&lang=${lang}`} variant="contained">
                        <FormattedMessage id="editor.button.open-prayers-of-the-faithful" defaultMessage="Print the prayers of the faithful"/>
                    </Button>
                }
                <Typography variant="h4" gutterBottom>
                    <FormattedMessage id="editor.date" defaultMessage="Date"/>
                </Typography>
                <Typography gutterBottom>
                    <FormattedMessage id="editor.datepicker.instructions" defaultMessage="Select a date. The changes made to the current date will be saved!"/>
                </Typography>
                {subscription && 
                    <DatePicker 
                        value={dayjs(date)} 
                        onChange={(value) => {setDate(formatDate(value));console.log(`DEBUG setDate(${formatDate(value)})`)}}/>
                }
                {!subscription && 
                    <DatePicker 
                        value={dayjs(date)} 
                        minDate={dayjs(new Date())}
                        maxDate={dayjs(one_week_after(new Date()))}
                        onChange={(value) => {setDate(formatDate(value));console.log(`DEBUG setDate(${formatDate(value)})`)}}/>
                }
                <Snackbar open={readyState !== ReadyState.OPEN}>
                    <Alert severity="error">{connectionStatus}</Alert>
                </Snackbar>
                { console.log(`Editor JSX rendering for ${JSON.stringify(patch).slice(0,130)}`)}
                { 
                    patch?.LiturgischeDag && 
                    <Part propData={patch.LiturgischeDag} send={applyPatch}>
                        <Proper id="LiturgischeDag" 
                            subscription={subscription} 
                            date={date}
                            name={intl.formatMessage({id:"editor.liturgical_day",defaultMessage:"Liturgical day"})} 
                            helperText={helperTexts['title']}/> 
                    </Part>
                }
                { 
                    patch?.Intredezang && 
                    <Part propData={patch.Intredezang} send={applyPatch}>
                        <Zang id="Intredezang" 
                            name={intl.formatMessage({id:"editor.introitus_hymn",defaultMessage:"Introitus hymn"})} 
                            helperText={helperTexts['custom_slide_break']}/>
                    </Part> 
                }
                { 
                    patch?.Confiteor && 
                    <Part propData={patch.Confiteor} send={applyPatch}>
                        <Confiteor id="Confiteor"
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.confiteor",defaultMessage:"Confiteor"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Kyrie && 
                    <Part propData={patch.Kyrie} send={applyPatch}>
                        <Ordinary id="Kyrie" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.kyrie",defaultMessage:"Kyrie"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Gloria && 
                    <Part propData={patch.Gloria} send={applyPatch}>
                        <Ordinary id="Gloria" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.gloria",defaultMessage:"Gloria"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.EersteLezing && 
                    <Part propData={patch.EersteLezing} send={applyPatch}>
                        <Proper id="EersteLezing" 
                            subscription={subscription} 
                            date={date}
                            name={intl.formatMessage({id:"editor.first_reading",defaultMessage:"First reading"})} 
                            helperText={helperTexts['automatic_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Psalm && 
                    <Part propData={patch.Psalm} send={applyPatch}>
                        <Proper id="Psalm" 
                            subscription={subscription} 
                            date={date}
                            name={intl.formatMessage({id:"editor.psalm",defaultMessage:"Psalm"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.TweedeLezing && 
                    <Part propData={patch.TweedeLezing} send={applyPatch}>
                        <Proper id="TweedeLezing" 
                            subscription={subscription} 
                            date={date}
                            name={intl.formatMessage({id:"editor.second_reading",defaultMessage:"Second reading"})} 
                            helperText={helperTexts['automatic_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.VersVoorHetEvangelie && 
                    <Part propData={patch.VersVoorHetEvangelie} send={applyPatch}>
                        <Proper id="VersVoorHetEvangelie" 
                            subscription={subscription} 
                            date={date}
                            name={intl.formatMessage({id:"editor.gospel_verse",defaultMessage:"Gospel verse"})} 
                            helperText={helperTexts['automatic_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Evangelie && 
                    <Part propData={patch.Evangelie} send={applyPatch}>
                        <Proper id="Evangelie" 
                            subscription={subscription} 
                            date={date}
                            name={intl.formatMessage({id:"editor.gospel",defaultMessage:"Gospel"})} 
                            helperText={helperTexts['automatic_slide_break']}
                            updateGospel={updateGospel}/> 
                    </Part>
                }
                { 
                    patch?.Credo && 
                    <Part propData={patch.Credo} send={applyPatch}>
                        <Creed id="Credo"
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.credo",defaultMessage:"Credo"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Voorbedelied && 
                    <Part propData={patch.Voorbedelied} send={applyPatch}>
                        <Voorbedelied id="Voorbedelied" 
                            name={intl.formatMessage({id:"editor.prayer_answer",defaultMessage:"Answer to the prayers of the faithful"})} 
                            helperText=""/>
                    </Part> 
                }
                { 
                    patch?.Voorbede1 && 
                    <Part propData={patch.Voorbede1} send={applyPatch}>
                        <Voorbede id="Voorbede1" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Voorbede2 && 
                    <Part propData={patch.Voorbede2} send={applyPatch}>
                        <Voorbede id="Voorbede2" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Voorbede3 && 
                    <Part propData={patch.Voorbede3} send={applyPatch}>
                        <Voorbede id="Voorbede3" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Voorbede4 && 
                    <Part propData={patch.Voorbede4} send={applyPatch}>
                        <Voorbede id="Voorbede4" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Voorbede5 && 
                    <Part propData={patch.Voorbede5} send={applyPatch}>
                        <Voorbede id="Voorbede5" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Voorbede6 && 
                    <Part propData={patch.Voorbede6} send={applyPatch}>
                        <Voorbede id="Voorbede6" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Voorbede7 && 
                    <Part propData={patch.Voorbede7} send={applyPatch}>
                        <Voorbede id="Voorbede7" subscription={subscription} gospel={gospel}/> 
                    </Part>
                }
                { 
                    patch?.Offerzang && 
                    <Part propData={patch.Offerzang} send={applyPatch}>
                        <Zang id="Offerzang" 
                            name={intl.formatMessage({id:"editor.offertorium_hymn",defaultMessage:"Offertorium hymn"})} 
                            helperText={helperTexts['custom_slide_break']}/>
                    </Part> 
                }
                { 
                    patch?.Offertorium && 
                    <Part propData={patch.Offertorium} send={applyPatch}>
                        <Ordinary id="Offertorium" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.offertorium",defaultMessage:"Offertorium"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.SursumCorda && 
                    <Part propData={patch.SursumCorda} send={applyPatch}>
                        <Ordinary id="SursumCorda" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.sursum_corda",defaultMessage:"Sursum corda"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Sanctus && 
                    <Part propData={patch.Sanctus} send={applyPatch}>
                        <Ordinary id="Sanctus" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.sanctus",defaultMessage:"Sanctus"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Mysterium &&
                    <Part propData={patch.Mysterium} send={applyPatch}>
                        <Ordinary id="Mysterium" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.mysterium",defaultMessage:"Mysterium Fidei"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.PaterNoster && 
                    <Part propData={patch.PaterNoster} send={applyPatch}>
                        <Ordinary id="PaterNoster" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.pater_noster",defaultMessage:"Pater noster"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.LiberaNos && 
                    <Part propData={patch.LiberaNos} send={applyPatch}>
                        <Ordinary id="LiberaNos" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.libera_nos",defaultMessage:"Libera nos"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.AgnusDei && 
                    <Part propData={patch.AgnusDei} send={applyPatch}>
                        <Ordinary id="AgnusDei" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.agnus_dei",defaultMessage:"Agnus Dei"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.NonSumDignus && 
                    <Part propData={patch.NonSumDignus} send={applyPatch}>
                        <Ordinary id="NonSumDignus" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.non_sum_dignus",defaultMessage:"Non sum dignus"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Communiezang && 
                    <Part propData={patch.Communiezang} send={applyPatch}>
                        <Zang id="Communiezang" 
                            name={intl.formatMessage({id:"editor.communion_hymn",defaultMessage:"Communion hymn"})} 
                            helperText={helperTexts['custom_slide_break']}/>
                    </Part> 
                }
                { 
                    patch?.RitusConclusionis && 
                    <Part propData={patch.RitusConclusionis} send={applyPatch}>
                        <Ordinary id="RitusConclusionis" 
                            subscription={subscription} 
                            name={intl.formatMessage({id:"editor.ritus_conclusionis",defaultMessage:"Ritus conlusionis"})} 
                            helperText={helperTexts['custom_slide_break']}/> 
                    </Part>
                }
                { 
                    patch?.Uittredezang && 
                    <Part propData={patch.Uittredezang} send={applyPatch}>
                        <Zang id="Uittredezang" 
                            name={intl.formatMessage({id:"editor.recessional_hymn",defaultMessage:"Recessional hymn"})} 
                            helperText={helperTexts['custom_slide_break']}/>
                    </Part> 
                }
                <Typography variant="body1">
                    <FormattedMessage id="editor.footer" values={{a: chunks => <a href='mailto:info@missale.net'>info@missale.net</a>}} defaultMessage="
                    Please share your thoughts about this site!"/>
                    <br/>
                    (<i><b>e</b></i>) <a href="mailto:info@missale.net">info@missale.net</a> (<i><b>f</b></i>) <a href={SupportedLanguagesFacebookURLs[lang]}>{SupportedLanguagesFacebookURLs[lang].replace('https://','')}</a>
                </Typography>
            </Stack>
        </Container>
    )
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function nextDate(dayIndex) {
    // Get the current date
    let today = new Date();

    // Get the current day of the week (0 = Sunday, 1 = Monday, ...)
    let dayOfWeek = today.getDay();

    // Calculate the number of days until next Sunday (7 - dayOfWeek)
    let daysUntilNextSunday = 7 - dayOfWeek;

    // If today is Sunday, daysUntilNextSunday should be 0
    if (daysUntilNextSunday === 7) {
        daysUntilNextSunday = 0;
    }

    // Calculate the date of next Sunday by adding daysUntilNextSunday to the current date
    let nextSunday = new Date(today.getTime() + daysUntilNextSunday * 24 * 60 * 60 * 1000);

    return nextSunday;
}


export default Editor
import {useState, useCallback, useMemo} from 'react';
import {useParams} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import {useQuery, useQueryClient} from '@tanstack/react-query'
import { api_proxy } from "../proxy";
import Typography from '@mui/material/Typography';
import {FormattedMessage, useIntl, defineMessages} from 'react-intl'

function Voorbede({id, patch, syncPatch, subscription, gospel}) {
    console.log(`Voorbede ${id} rendering`)
    const intl = useIntl()
    //console.log(`DEBUG ${JSON.stringify(patch)}`)

    // setup react state
    const { lang } = useParams();

    // translating source to vernacular triggered by typing in source
    const queryClient = useQueryClient()
    const {isFetching: isFetchingVernacular} = useQuery([patch.source], async ({signal}) => {
        console.log(`Voorbede ${id} effect depending on [patch.source] = [${patch.source}]`)
        if (patch.source) {
            const res = await fetch(`https://${window.location.hostname}${api_proxy}/translate?text=${patch.source}&lang=${lang}`, {signal});
            const data = await res.json()
            syncPatch({text: data.message})
            return data
        }
        else return false
    }, {
        refetchOnWindowFocus: false
    }); 

    // translating vernacular to other language triggered by clicking language button
    const [fetchingData, setFetchingData] = useState("")  // holds the language while fetching translation
    const {isFetching: isFetchingTranslation} = useQuery([fetchingData], async () => {
        console.log(`Voorbede ${id} effect depending on [fetchingData] = [${fetchingData}]`)
        if (fetchingData) {
            const res = await fetch(`https://${window.location.hostname}${api_proxy}/translate?text=${patch.text}&lang=${fetchingData}`);
            const data = await res.json()
            syncPatch({translation: data.message, language: fetchingData})
            setFetchingData(false)
            return data
        }
        else return false
    }, {
        refetchOnWindowFocus: false
    }); 

    // fetching from chatgpt
    const [fetchingChatGPT, setFetchingChatGPT] = useState(false)
    const {isFetching: isFetchingChatGPT} = useQuery([fetchingChatGPT], async () => {
        console.log(`Voorbede ${id} effect depending on [fetchingChatGPT] = [${fetchingChatGPT}]`)
        if (fetchingChatGPT) {
            const res = await fetch(`https://${window.location.hostname}${api_proxy}/chatgpt?lang=${lang}&intercession=${intl.formatMessage(subtitle_messages[id])}&gospel=${encodeURIComponent(gospel.replace(/"/g, "'").replace(/\n/g, " ")).slice(0,1000)}`);
            const data = await res.json()
            syncPatch({text: data.message, source: "", translation: ""})
            setFetchingChatGPT(false)
            return data
        }
        else return false
    }, {
        refetchOnWindowFocus: false
    })

    const isFetching = isFetchingTranslation || isFetchingVernacular || isFetchingChatGPT

    // user presses button to load data from translation API
    const translate = useCallback((e) => {
        console.log(`Voorbede ${id} button handler translate`)
        setFetchingData(e.target.dataset.lang)  // the target language
    }, [id])
  
    // user presses button to load from chatgpt
    const chatgpt = useCallback((e) => {
        console.log(`Voorbede ${id} button handler chatgpt`)
        setFetchingChatGPT(true)
    }, [id])
    
    // user editing text
    const handleText = useCallback((e) => {
        console.log(`Voorbede ${id} onchange handler for vernacular text`)
        syncPatch({text: e.target.value})
    }, [id])

    // user editing source
    const handleSource = useCallback((e) => {
        console.log(`Voorbede ${id} onchange handler for source text`)
        queryClient.cancelQueries({queryKey: [lang]})
        syncPatch({source: e.target.value})
    }, [id])

    // user editing translation
    const handleTranslation = useCallback((e) => {
        console.log(`Voorbede ${id} onchange handler for translation`)
        syncPatch({translation: e.target.value})
    }, [id])

    // user presses button to clear data
    const clear = useCallback(() => {
        console.log(`Voorbede ${id} button handler clear`)
        syncPatch({text: "", source: "", translation: ""})
    }, [id])

    const language_button_messages = defineMessages({
        nl: {id: "voorbede.language.nl", defaultMessage: "Dutch"},
        pl: {id: "voorbede.language.pl", defaultMessage: "Polish"},
        fr: {id: "voorbede.language.fr", defaultMessage: "French"},
        es: {id: "voorbede.language.es", defaultMessage: "Spanish"},
        "pt-br": {id: "voorbede.language.pt-br", defaultMessage: "Portuguese"},
        en: {id: "voorbede.language.en", defaultMessage: "English"},
        be: {id: "voorbede.language.be", defaultMessage: "Belarussian"},
        "zh-cn": {id: "voorbede.language.zh-cn", defaultMessage: "Chinese"},
        ta: {id: "voorbede.language.ta", defaultMessage: "Tamil"},
        de: {id: "voorbede.language.de", defaultMessage: "German"},
        it: {id: "voorbede.language.it", defaultMessage: "Italian"}
    })
    
    const language_buttons = ["nl", "pl", "fr", "es", "pt-br", "en", "be", "zh-cn", "ta", "de", "it"].map(l => 
        (
            l != lang && <Button onClick={translate} data-lang={l} variant="outlined">
                <FormattedMessage {...language_button_messages[l]}/>
            </Button> 
        )
    )

    const subtitle_messages = defineMessages({
        "Voorbede1": {id: "voorbede.subtitle.voorbede1", defaultMessage: "For the needs of the universal Church"},
        "Voorbede2": {id: "voorbede.subtitle.voorbede2", defaultMessage: "For our public authorities"},
        "Voorbede3": {id: "voorbede.subtitle.voorbede3", defaultMessage: "For the salvation of the world"},
        "Voorbede4": {id: "voorbede.subtitle.voorbede4", defaultMessage: "For people burdoned by any kind of difficulty"},
        "Voorbede5": {id: "voorbede.subtitle.voorbede5", defaultMessage: "For our local community"},
        "Voorbede6": {id: "voorbede.subtitle.voorbede6", defaultMessage: "For special intercessions"},
        "Voorbede7": {id: "voorbede.subtitle.voorbede7", defaultMessage: "For the repose of the souls of the deceased"},
    })
    return (
        <Stack spacing={4}>
            <Typography variant="h4" gutterBottom>
                <FormattedMessage id="voorbede.title" defaultMessage="Prayer of the faithful"/>
            </Typography>
            <Typography variant="h5" gutterBottom>
                <FormattedMessage {...subtitle_messages[id]}/>
            </Typography>
            <ButtonGroup variant="outlined" fullWidth={true}>
                <Button onClick={clear} startIcon={<DeleteIcon />}/>
                { subscription && 
                    <Button onClick={chatgpt}>
                        <FormattedMessage 
                            id="voorbede.chatgpt" 
                            defaultMessage="ChatGPT"/>
                    </Button>
                }
            </ButtonGroup>
            <TextField disabled={!!isFetching} value={patch.text} helperText={intl.formatMessage({id:"voorbede.helpertext.vernacular",defaultMessage:"the prayer in the vernacular is displayed on the slides"})} onChange={handleText} multiline maxRows={100} variant="filled" fullWidth/>
            { subscription && 
                <Stack spacing={4}>
                    <TextField value={patch.source} helperText={intl.formatMessage({id:"voorbede.helpertext.source",defaultMessage:"paste a prayer in any language, to be automatically translated into the vernacular"})} onChange={handleSource} multiline maxRows={100} variant="filled" fullWidth/>
                    <Grid container>
                        { language_buttons }
                    </Grid>
                    <TextField disabled={!!isFetching} value={patch.translation} helperText={intl.formatMessage({id:"voorbede.helpertext.translation",defaultMessage:"the translated prayer is not displayed on the slides, but available as printout for the lector"})} onChange={handleTranslation} multiline maxRows={100} variant="filled" fullWidth/>
                </Stack>
            }
        </Stack>
    )
}

export default Voorbede

import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from 'react-intl'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ShareIcon from '@mui/icons-material/Share';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkout from './Checkout'
import TextFieldValidEmail from './TextFieldValidEmail'

function Account({subscription, amount, currency, sendEmail, expand}) {
    const intl = useIntl()
    const days_left = useMemo(() => {
        if (subscription) {
            return Math.ceil((Date.parse(subscription) - Date.now())/1000/60/60/24)
        }
        else {
            return null
        }
    }, [subscription])

    const submit = event => {
        event.preventDefault()
        if (event.target.email.value) {
            sendEmail({
                email: event.target.email.value, 
                model: "confirmation"
            })
        }
    }

    const submit_order_confirmation = event => {
        event.preventDefault()
        if (event.target.email.value) {
            sendEmail({
                email: event.target.email.value, 
                model: "payment", 
                parameters: {
                    subscription,
                    amount,
                    currency
                }
            })
        }
    }

    const features = [
        {feature: intl.formatMessage({id: "account.features.create-secret-url", defaultMessage: "Create secret URL for your parish"}), free: "☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.share-secret-url", defaultMessage: "Share secret URL with other editors"}), free: "☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.edit-coming-week", defaultMessage: "Create slides for coming week"}), free: "☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.edit-any-date", defaultMessage: "Create slides for any future date"}), free: "", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.retrieve-past-dates", defaultMessage: "Retrieve slides from past dates"}), free: "", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.load-fixed-prayers", defaultMessage: "Load fixed prayers in Latin or vernacular from missal"}), free: "☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.edit-fixed-prayers", defaultMessage: "Edit fixed prayers"}), free: "", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.load-readings", defaultMessage: "Load readings from missal"}), free: "☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.edit-readings", defaultMessage: "Edit reading texts"}), free: "", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.add-hymns", defaultMessage: "Add hymn texts"}), free: "️☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.add-prayers", defaultMessage: "Add prayers of the faithful"}), free: "☑️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.chatgpt-prayers", defaultMessage: "Use ChatGPT to write prayers of the faithful"}), free: "️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.translate-prayers", defaultMessage: "Translate prayers of the faithful from/to any language"}), free: "️", pro: "☑️"},
        {feature: intl.formatMessage({id: "account.features.print-prayers", defaultMessage: "Print a sheet containing prayers of the faithful in both languages"}), free: "️", pro: "☑️"}
    ]

    return (
        <Paper elevation={3}>
            <Accordion defaultExpanded={expand || (days_left > 0 && days_left < 15)}>
                <AccordionSummary
                    expandIcon={<ShareIcon />}>
                    <Typography gutterBottom>
                        <FormattedMessage id="account.button.share_account" defaultMessage="Account information"/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={4}>
                        <Divider>
                            <FormattedMessage id="account.account.divider.url" defaultMessage="Save or share secret URL"/>
                        </Divider>
                        <Stack spacing={1}>
                            <TextField disabled={true} variant="outlined" value={window.location.protocol + '//' + window.location.host + window.location.pathname} fullWidth/>
                            <Button 
                                onClick={() => copy(window.location.protocol + '//' + window.location.host + window.location.pathname)} 
                                startIcon={<ContentCopyIcon />} 
                                variant="contained">
                                <FormattedMessage id="account.button.copy_url" defaultMessage="Copy the secret URL"/>
                            </Button>
                        </Stack>
                        <form onSubmit={submit}>
                            <Stack spacing={1}>
                                <TextFieldValidEmail
                                    name="email" 
                                    variant="outlined" 
                                    required 
                                    label={intl.formatMessage({id: 'account.email.label', defaultMessage: 'Email address'})} 
                                    fullWidth/>
                                <Button type="submit" variant="contained">
                                    <FormattedMessage id="account.email.button" defaultMessage="
                                    Share secret URL via email"/>
                                </Button>
                            </Stack>
                        </form>
                        <Divider>
                            <FormattedMessage id="account.account.divider.pro" defaultMessage="Use extended features"/>
                        </Divider>
                        {
                            subscription && days_left >= 0 && 
                                <Typography gutterBottom>
                                    <FormattedMessage id="account.status.running" values={{days_left}} defaultMessage="Your subscription will run for another {days_left} days."/>
                                </Typography>
                            
                        }
                        {
                            days_left < 0 &&
                            <Typography gutterBottom>
                                <FormattedMessage id="account.status.expired" defaultMessage="Your subscription has expired. You're using a free account."/>
                            </Typography>
                        }
                        {
                            !subscription &&
                            <Typography gutterBottom>
                                <FormattedMessage id="account.status.none" defaultMessage="You're using a free account."/>
                            </Typography>
                        }
                        {
                            subscription && days_left >= 15 &&
                            <form onSubmit={submit_order_confirmation}>
                                <Stack spacing={1}>
                                    <TextFieldValidEmail
                                        name="email" 
                                        variant="outlined" 
                                        required 
                                        label={intl.formatMessage({id: 'account.order-confirmation.email.label', defaultMessage: 'Email address'})} 
                                        fullWidth/>
                                    <Button type="submit" variant="contained">
                                        <FormattedMessage id="account.order-confirmation.email.button" defaultMessage="
                                        Receive order statement via email"/>
                                    </Button>
                                </Stack>
                            </form>
                        }
                        {
                            days_left > 0 && days_left < 15 && 
                            <Checkout message={intl.formatMessage({id: "account.checkout.pay_extra", defaultMessage: "Pay now to use the extended features for one extra year after that."})}/>
                        }
                        {
                            (!subscription || days_left < 0) &&
                            <Checkout message={intl.formatMessage({id: "account.status.pay", defaultMessage: "Pay now to use the extended features for one year."})}/>
                        }
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id="account.featurs_table_header.features" defaultMessage="Features"/>
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="account.featurs_table_header.free" defaultMessage="Free"/>
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="account.featurs_table_header.extended" defaultMessage="Extended"/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {features.map((row) => (
                                    <TableRow
                                        key={row.feature}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="right">{row.feature}</TableCell>
                                        <TableCell align="right">{row.free}</TableCell>
                                        <TableCell align="right">{row.pro}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}

export default Account